<template>
 <section class="login-content">
         <div class="container">
            <div class="row align-items-center justify-content-center height-self-center">
               <div class="col-lg-8">
                  <div class="card auth-card">
                     <div class="card-body p-0">
                        <div class="d-flex align-items-center auth-content">
                           <div class="col-lg-6  content-left">
                              <div class="p-3">
                                 <h2 class="mb-2 ">Inscrever-se</h2>
                                 <p>Crie a sua Conta!</p>
                                 <form @submit.prevent="onSubmit()">
                                    <div class="row">
                                       <div class="col-lg-6">
                                          <div class="form-group">
                                             <input 
                                                class="floating-input form-control" 
                                                :class="`form-control ${$v.fname.$error ? 'is-invalid' : ''}`" 
                                                v-model="fname" 
                                                type="text" 
                                                placeholder="" 
                                                @input="$v.fname.$touch()" 
                                                @blur="$v.fname.$touch()"
                                             >
                                             <label>Primeiro Nome</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="form-group">
                                             <input 
                                                class="floating-input form-control" 
                                                :class="`form-control ${$v.lname.$error ? 'is-invalid' : ''}`" 
                                                v-model="lname" 
                                                type="text" 
                                                placeholder="" 
                                                @input="$v.lname.$touch()" 
                                                @blur="$v.lname.$touch()"
                                             >
                                             <label>Último Nome</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="form-group">
                                             <input 
                                                class="floating-input form-control" 
                                                :class="`form-control ${$v.email.$error ? 'is-invalid' : ''}`" 
                                                v-model="email" 
                                                type="email" 
                                                placeholder="" 
                                                @input="$v.email.$touch()" 
                                                @blur="$v.email.$touch()"
                                             >
                                             <label>Email</label>
                                             <div class="invalid-feedback">
                                                <span v-if="!$v.email.email">Email inválido</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="form-group">
                                             <input 
                                                class="floating-input form-control" 
                                                :class="`form-control ${$v.phoneNumber.$error ? 'is-invalid' : ''}`" 
                                                v-model.number="phoneNumber" 
                                                type="text" 
                                                placeholder="" 
                                                @input="$v.phoneNumber.$touch()" 
                                                @blur="$v.phoneNumber.$touch()"
                                             >
                                             <label>Número de Telemóvel</label>
                                             <div class="invalid-feedback">
                                                <span v-if="!$v.phoneNumber.integer">Número de Telemóvel inválido</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="form-group">
                                             <input 
                                                class="floating-input form-control" 
                                                :class="`form-control ${$v.pwd.$error ? 'is-invalid' : ''}`" 
                                                v-model="pwd" 
                                                type="password" 
                                                placeholder="" 
                                                @input="$v.pwd.$touch()" 
                                                @blur="$v.pwd.$touch()"
                                             >
                                             <label>Palavra-passe</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="form-group">
                                             <input 
                                                class="floating-input form-control" 
                                                :class="`form-control ${$v.cpwd.$error ? 'is-invalid' : ''}`" 
                                                v-model="cpwd" 
                                                type="password" 
                                                placeholder="" 
                                                @input="$v.cpwd.$touch()" 
                                                @blur="$v.cpwd.$touch()"
                                             >
                                             <label>Confirmar Palavra-passe</label>
                                             <div class="invalid-feedback">
                                                <span v-if="!$v.cpwd.sameAs">As senhas não coincidem</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-lg-12">
                                          <div class="custom-control custom-checkbox mb-3">
                                             <input type="checkbox" class="custom-control-input" id="customCheck1">
                                             <label class="custom-control-label " for="customCheck1">Estou de acordo com os termos de utilização</label>
                                          </div>
                                       </div>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Inscreva-se</button>
                                    <p class="mt-3">
                                      Já tem conta <router-link :to="{name: 'auth.login'}" class="text-primary">Iniciar Sessão</router-link>
                                    </p>
                                 </form>
                              </div>
                           </div>
                           <div class="col-lg-6 content-right">
                              <div class="row">
                                 <div class="col-12">
                                    <img src="@/assets/images/logo.svg" class="img-fluid image-right" alt="">
                                 </div>
                                 <div class="col-12">
                                    <img class="w-100" src="@/assets/images/footer.png" alt="">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email, sameAs, integer } from 'vuelidate/lib/validators'

export default {
    name:'SignUp',
    mixins: [validationMixin],
    validations: {
      fname: { required },
      lname: { required },
      email:{ required , email },
      phoneNumber: { required, integer },
      pwd: { required },
      cpwd: { required, sameAsPassword : sameAs('pwd') }
    },
    data() {
      return {
         fname: '',
         lname: '',
         email: '',
         phoneNumber: '',
         pwd: '',
         cpwd: ''
      }
    },
    methods:{
       onSubmit()
       {
         this.$v.$touch();
         if(!this.$v.$invalid) {
            this.$router.push({name:'auth.login'})
         }
       }
    }
}
</script>